<template>
  <div class="page-login h-screen flex items-start justify-center pt-32">
    <div class="login-form">
      <p class="text-4xl text-primary text-center font-semibold">选股宝后台 ✨</p>
      <div class="flex items-center justify-center">
        <el-form size="medium" style="width: 400px" :model="form" :rules="rules" ref="formRef">
          <el-form-item label="邮箱" prop="email">
            <el-input
              placeholder="请输入"
              type="email"
              v-model.trim="form.email"
              @keyup.enter="onSubmit"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              placeholder="请输入"
              v-model="form.password"
              type="password"
              @keyup.enter="onSubmit"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="captcha">
            <el-input
              placeholder="请输入"
              v-model="form.captcha"
              type="captcha"
              @keyup.enter="onSubmit"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="flex justify-between">
              <el-button
                :disabled="sec > 0"
                class="captcha-btn w-2/5"
                @click="sendCaptcha"
              >{{ countdownText || '发送验证码' }}</el-button>
              <el-button
                type="primary"
                class="submit-btn w-2/5"
                :disabled="!form.captcha || logining"
                :loading="logining"
                @click="onSubmit"
              >登录</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { isProd } from '@/utils'
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { userStore } from '@/store/modules/user'
import type { LoginResult } from '@/domains/user/models/user'
// import { ApiErr } from '@/http/wscn/err'

export default defineComponent({
  setup() {
    // * 若登录 重定向首页
    if (userStore.getToken) {
      useRouter().replace('/')
    }

    const form = reactive({
      email: (!isProd && '@wallstreetcn.com') || '',
      password: (!isProd && '123456a') || '',
      captcha: (!isProd && '123456') || ''
    })
    const rules = reactive({
      email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
      password: [{ required: true, message: '请输入密码', trigger: 'change' }],
      captcha: [{ required: true, message: '请输入验证码', trigger: 'change' }]
    })
    const sec = ref(0)
    const countdownText = ref('')

    const formRef = ref<any>()
    const logining = ref<boolean>(false)

    // * 倒计时
    function startCountdown() {
      setTimeout(() => {
        countdownText.value = `${sec.value}秒后重新发送`
        sec.value -= 1
        if (sec.value < 0) {
          countdownText.value = ''
          sec.value = 60
        } else {
          startCountdown()
        }
      }, 1000)
    }

    // * 发送验证码
    function sendCaptcha() {
      if (!form.email) {
        return ElMessage.error('请输入邮箱！')
      }
      if (sec.value > 0) return
      sec.value = 60
      startCountdown()

      try {
        userStore.dispatchSendCaptcha(form.email)
        ElMessage.info('请去企业微信绑定的手机号上面查看验证码！')
      } catch (error) {
        ElMessage.error(error.message)
      }
    }

    // * 登录
    function onSubmit() {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          logining.value = true
          userStore
            .dispatchLogin(form)
            .then((tokenPayload: LoginResult | null) => {
              if (tokenPayload) {
                setTimeout(() => location.replace('/'), 200)
              }
            })
            .finally(() => {
              logining.value = false
            })
        }
      })
    }

    return {
      form,
      rules,
      sec,
      countdownText,
      formRef,
      sendCaptcha,
      onSubmit,
      logining
    }
  }
})
</script>
<style lang="scss">
.page-login {
  background-image: url('../assets/bg.svg');
  background-size: cover;
  .login-form {
    background: #00000008;
    border-radius: 10px;
    padding: 20px;
  }
}
</style>
