
import { isProd } from '@/utils'
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { userStore } from '@/store/modules/user'
import type { LoginResult } from '@/domains/user/models/user'
// import { ApiErr } from '@/http/wscn/err'

export default defineComponent({
  setup() {
    // * 若登录 重定向首页
    if (userStore.getToken) {
      useRouter().replace('/')
    }

    const form = reactive({
      email: (!isProd && '@wallstreetcn.com') || '',
      password: (!isProd && '123456a') || '',
      captcha: (!isProd && '123456') || ''
    })
    const rules = reactive({
      email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
      password: [{ required: true, message: '请输入密码', trigger: 'change' }],
      captcha: [{ required: true, message: '请输入验证码', trigger: 'change' }]
    })
    const sec = ref(0)
    const countdownText = ref('')

    const formRef = ref<any>()
    const logining = ref<boolean>(false)

    // * 倒计时
    function startCountdown() {
      setTimeout(() => {
        countdownText.value = `${sec.value}秒后重新发送`
        sec.value -= 1
        if (sec.value < 0) {
          countdownText.value = ''
          sec.value = 60
        } else {
          startCountdown()
        }
      }, 1000)
    }

    // * 发送验证码
    function sendCaptcha() {
      if (!form.email) {
        return ElMessage.error('请输入邮箱！')
      }
      if (sec.value > 0) return
      sec.value = 60
      startCountdown()

      try {
        userStore.dispatchSendCaptcha(form.email)
        ElMessage.info('请去企业微信绑定的手机号上面查看验证码！')
      } catch (error) {
        ElMessage.error(error.message)
      }
    }

    // * 登录
    function onSubmit() {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          logining.value = true
          userStore
            .dispatchLogin(form)
            .then((tokenPayload: LoginResult | null) => {
              if (tokenPayload) {
                setTimeout(() => location.replace('/'), 200)
              }
            })
            .finally(() => {
              logining.value = false
            })
        }
      })
    }

    return {
      form,
      rules,
      sec,
      countdownText,
      formRef,
      sendCaptcha,
      onSubmit,
      logining
    }
  }
})
